import React, { useEffect, useState } from "react";
import Navbar from "./navbar";

import moment from "moment";
import 'moment/locale/pt-br';

import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/900.css';
import '../styles/template.scss';
import '../styles/bible-selector.scss';
import Footer from "./footer";
import { SiteSiteMetadata } from "..";

export const SongNumberContext = React.createContext<string|null>(null);

interface LayoutProps {
    site: SiteSiteMetadata,
    location: typeof window.location;
    title: string;
    isPreview?: boolean;
    children: any;
    pageTitle?: string;
    pageHeaderComponent?: JSX.Element;
    pageHeaderClass?: string;
    pageDate?: string;
    hideAddresses?: boolean
}

export function OuterLayout({
    site, location, title, children, isPreview
}: LayoutProps) {
    let Element: any = 'main' as any;
    const elementProps: any = {};

    if (isPreview) {
        Element = 'div' as any;
    }

    if (!isPreview) {
        <script>{`
            window['bible_queries_api_key'] = '${site?.bible_queries_api_key}';
        `}</script>
    }

    return <Element {...elementProps}>
        {!isPreview && <Navbar site={site} location={location} title={title} />}
        {children}
        {!isPreview && <Footer site={site} />}
    </Element>;
}

export default function Layout({
    site, location, title, children, pageTitle, pageHeaderComponent, pageDate, hideAddresses, pageHeaderClass
}: LayoutProps) {
    const elementProps: any = {};

    return <>
        {pageTitle &&
        <header className={`container margin-top ${pageHeaderClass}`}>
            {!pageHeaderComponent && <h1 itemProp="headline">{pageTitle}</h1>}
            {pageDate && <p>{moment(pageDate).local().format('DD \\d\\e MMMM \\d\\e YYYY, HH:mm')}</p>}
            {pageHeaderComponent || null}
        </header>}
        {children}
    </>;
}
