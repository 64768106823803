import React, { createContext, useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";

export function createDefaultLayoutOptions(): LayoutOptions {
    return {
        omitDuplicateVerses: true,
        showChords: false,
        hideHeaderQRCodes: false,
        showMusicAttributes: false,
    };
}

export const LayoutOptionsContext = createContext<LayoutOptionsContextI>({
    layoutOptions: createDefaultLayoutOptions(),
    setLayoutOptions(_) {},
});

export interface LayoutOptionsContextI {
    layoutOptions: LayoutOptions;
    setLayoutOptions: (options: Partial<LayoutOptions>) => void
}

export interface LayoutOptions {
    omitDuplicateVerses?: boolean;
    showChords?: boolean;
    showMusicAttributes?: boolean;
    hideHeaderQRCodes?: boolean;
}

export function useLayoutOptionsLoader(isPreview?: boolean): LayoutOptionsContextI {
    const [layoutOptions, _setLayoutOptions] = useState(createDefaultLayoutOptions());

    const setLayoutOptions = (newLayoutOptions: Partial<LayoutOptions>) => {
        const layoutOptionsObject = {...layoutOptions, ...newLayoutOptions};
        _setLayoutOptions(layoutOptionsObject);

        if (!isPreview) {
            localStorage.setItem('song-layout-options', JSON.stringify(layoutOptionsObject));
        }
    }

    useEffect(() => {
        if (!isPreview) {
            const storedLayoutOptions = localStorage.getItem('song-layout-options');

            if (storedLayoutOptions) {
                const parsedStoredLayoutOptions = JSON.parse(storedLayoutOptions);

                if (parsedStoredLayoutOptions) {
                    _setLayoutOptions(parsedStoredLayoutOptions);
                }
            }
        }
    }, []);

    return {layoutOptions, setLayoutOptions};
}


export function LayoutOptions({isPreview}: {isPreview?: boolean}) {
    const {layoutOptions, setLayoutOptions} = useContext(LayoutOptionsContext);

    return <div className="layout-options">
        <Form.Check
            type="switch"
            label="Omitir versos repetidos"
            id="options-omit-duplicate-verses"
            checked={layoutOptions?.omitDuplicateVerses}
            onChange={(e) => setLayoutOptions({omitDuplicateVerses: e.target.checked})}
        />
        <Form.Check
            type="switch"
            label="Exibir acordes"
            id="options-show-chords"
            checked={layoutOptions?.showChords}
            onChange={(e) => setLayoutOptions({showChords: e.target.checked})}
        />
        <Form.Check
            type="switch"
            label="Exibir atributos da música"
            id="options-show-music-attributes"
            checked={layoutOptions?.showMusicAttributes}
            onChange={(e) => setLayoutOptions({showMusicAttributes: e.target.checked})}
        />
        <Form.Check
            type="switch"
            label="Ocultar QR Codes do Cabeçalho"
            id="options-hide-header-qr-codes"
            checked={layoutOptions?.hideHeaderQRCodes}
            onChange={(e) => setLayoutOptions({hideHeaderQRCodes: e.target.checked})}
        />
    </div>;
}