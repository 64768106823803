import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { SiteSiteMetadata } from '..';
import { CopyrightNotice } from '../branding/copyright';

interface FooterProps {
    site: SiteSiteMetadata,
    hideAddresses?: boolean
}

export default function Footer({site, hideAddresses}: FooterProps) {
    return <footer className="bg-light pt-5 pb-5 text-center">
        <div className="footer-logos">
            <a href="https://ipjo.joinville.br" target="_blank" title="Igreja Presbiteriana Jardim das Oliveiras (Joinville/SC)">
                <StaticImage src="../images/logo.svg" alt={"Igreja Presbiteriana Jardim das Oliveiras"} className="footer-logos__ipjo"/>
            </a>
            <a href="https://ipb.org.br" target="_blank" title="Igreja Presbiteriana do Brasil" className="footer-logos__with-text">
                <span>federada à</span>
                <StaticImage src="../images/logo-ipb.svg" alt={"Igreja Presbiteriana do Brasil"} className="footer-logos__ipb"/>
            </a>
        </div>
        <div className="copyright container">
            <CopyrightNotice site={site} />
        </div>
        
    </footer>
}