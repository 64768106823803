
import { navigate } from "gatsby";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";

import { MdSearch } from 'react-icons/md';
import { CurrentSongbookContext } from "../templates/songbooks/songbooks";
import { IMaskInput } from "react-imask";

const NUMBER_REGEX = /^\d+$/;

export function SongSelectorByReference({onClose}: {onClose?: () => void}) {
    const [showNumberModal, setShowNumberModal] = useState(false);
    const [currentNumber, _setCurrentNumber] = useState<number|null>(null);
    const [enableGo, setEnableGo] = useState(false);
    const {songbook, reference} = useContext(CurrentSongbookContext);

    const closeNumberModal = () => {
        setShowNumberModal(false);
    };

    const openNumberModal = () => {
        setCurrentNumber(null);
        setShowNumberModal(true);
    }

    const setCurrentNumber = (newNumber: string | number | null) => {
        if (!newNumber || !((newNumber as string).trim())) {
            _setCurrentNumber(null);
            setEnableGo(false);
        } else {
            _setCurrentNumber(newNumber!);
            setEnableGo(true)
        }
    }

    const goToNumber = () => {
        if (enableGo) {
            navigate(`/${songbook?.acronym?.toLowerCase()}/${currentNumber}`);
            closeNumberModal();
            onClose?.();
        }
    }

    useEffect(() => {
        const handleKeyPresses = (e: KeyboardEvent) => {
            if (e.key == '/') {
                e.stopPropagation();
                e.preventDefault();
                openNumberModal();
            }
        }
        window.addEventListener('keypress', handleKeyPresses);

        return () => {
            window.removeEventListener('keypress', handleKeyPresses);
        }
    }, []);

    const mask = useMemo(() => songbook?.reference_mask?.split('|'), [songbook]);

    if (songbook) {
        return <>
            <Modal show={showNumberModal} onHide={closeNumberModal}>
                <Modal.Body>
                    <Badge className={"song__songbook song__songbook--list"}>{songbook?.name}</Badge>
                    <p style={{fontSize: '1.25em'}}>Digite o número da música:</p>
                    <IMaskInput
                        autoFocus
                        mask={mask}
                        type="number"
                        className="form-control"
                        style={{fontSize: '1.5em', fontWeight: 600}}
                        min={0}
                        max={999}
                        maxLength={3}
                        value={currentNumber}
                        onKeyPress={(event) => {
                            if (event.key == 'Enter') {
                                goToNumber();
                            }
                        }}
                        onAccept={(e) => setCurrentNumber(e)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeNumberModal}>Fechar</Button>
                    <Button variant="primary" disabled={!enableGo} onClick={goToNumber}>Ir</Button>
                </Modal.Footer>
            </Modal>
            <Button onClick={openNumberModal} className="d-flex align-items-center ms-auto ms-lg-1 me-lg-3">
                <MdSearch size="1.5rem" className="me-lg-1" />
                <span className="">Hino por Nº</span>
                <span className="d-lg-inline d-none"><kbd className="ms-2">/</kbd></span>
            </Button>
        </>;
    } else {
        return null;
    }
}