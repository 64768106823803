import { Link, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Modal, NavDropdown } from 'react-bootstrap';
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { MdManageSearch } from "react-icons/md";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import { SiteSiteMetadata, Songbook } from "..";
import { SongSelectorByReference } from './song-selector-by-reference';
import { CurrentSongbookContext } from '../templates/songbooks/songbooks';
import { PrintHeader } from '../branding/print-header';
import { LayoutOptions } from './layout-options';

interface NavbarProps {
    location: typeof window.location,
    site: SiteSiteMetadata,
    title: string    
}


export default function HeaderNavbar({title, site, location}: NavbarProps) {
    const {songbook} = useContext(CurrentSongbookContext);
    const breakpoints = useBreakpoint();
    const [showNavigationOffcanvas, setShowNavigationOffcanvas] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        setHasLoaded(true);
    }, []);

    return <>
        <Navbar bg="light" expand="lg" className="header-navbar header-navbar--first" style={{zIndex: 101}}>
            <Container>
                {(!hasLoaded || breakpoints.md) && <>
                    <Button onClick={() => setShowNavigationOffcanvas(true)} className="d-inline-flex align-items-center button-navigate" style={{fontSize: '0.85rem'}}>
                        <MdManageSearch size="1.5rem" className="me-1" />
                        Navegar
                    </Button>
                </>}
                <Link className="navbar-brand main-logo" to={'/'} title={title}>
                    <StaticImage src="../images/logo.svg" alt={title || ''} />
                </Link>
                <Navbar.Toggle aria-controls="offcanvas-menu" className="ms-2" />
                <Navbar.Offcanvas id={`offcanvas-menu`} aria-labelledby="offcanvas-menu-label" placement="end">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvas-menu-label`}>
                            Menu e Opções
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="me-auto" style={{gap: '1em'}}>
                            {hasLoaded && !breakpoints.md && <>
                                <NavSongbookItems location={location} />
                                <div className="vr ms-2 me-2" />
                                <NavDropdown title="Opções" className="layout-options-dropdown">
                                    <LayoutOptions />
                                </NavDropdown>
                            </>}
                            {hasLoaded && breakpoints.md && <LayoutOptions />}
                            <Nav.Item><a className="nav-link" href={site?.main_site_url}>Site da IPJO</a></Nav.Item>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
        {songbook && !breakpoints.md && <Navbar bg="light" expand="lg" className={`header-navbar header-navbar--second ${!hasLoaded ? 'loading' : ''}`}>
            <Container>
                <SongSelectorByReference />
                <SongbookLinks songbook={songbook} location={location} />
            </Container>
        </Navbar>}
        <div className="print-header">
            <PrintHeader site={site} />
        </div>
        <MobileOffcanvas
            show={showNavigationOffcanvas}
            handleClose={() => setShowNavigationOffcanvas(false)}
            location={location}
        />
    </>;
}

const SONGBOOKS = [
    {acronym: 'CPJO', label: 'Cânticos IPJO'},
    {acronym: 'HNC', label: 'Hinário Novo Cântico'},
]

function NavSongbookItems({location, useInnerLinks, onClick}: {location: Location, useInnerLinks?: boolean, onClick: () => void}) {
    const {songbook} = useContext(CurrentSongbookContext);
    const [activeAcronym, setActiveAcronym] = useState(songbook?.acronym);

    useEffect(() => setActiveAcronym(songbook?.acronym), [songbook]);

    return SONGBOOKS.map(({acronym, label}) => <Fragment key={acronym}>
        <Nav.Item>
            <Nav.Link
                as={Link}
                to={`/${acronym.toLowerCase()}/`}
                active={acronym === activeAcronym}
                onClick={() => setActiveAcronym(songbook?.acronym)}
            >
                {label}
            </Nav.Link>
        </Nav.Item>
        {useInnerLinks && (songbook?.acronym == acronym) && <SongbookLinks isInner songbook={songbook} location={location} onClick={onClick} />}
    </Fragment>);
}

function MobileOffcanvas({show, handleClose, location}: {show: boolean; handleClose: () => void, location: Location}) {
    const breakpoints = useBreakpoint();

    return breakpoints.lg && <>
        <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    Navegar pelos Hinários
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="navbar navigation">
                <Nav className="navigation--first">
                    <NavSongbookItems location={location} useInnerLinks={true} onClick={() => handleClose()} />
                </Nav>
                <SongSelectorByReference onClose={handleClose} />
            </Offcanvas.Body>
        </Offcanvas>
</>;
}

function SongbookLinks(
    {songbook, location, isInner, onClick}:
    {songbook: Songbook | null, location: Location, isInner?: boolean, onClick?: (songbook: Songbook | null, path: string) => void}
) {
    return <Nav activeKey={location.pathname} className={`me-auto${isInner ? ' nav--inner' : ''}`} style={{gap: '1em'}}>
        <SongbookLink path="" acronym={songbook?.acronym ?? ''} currentPath={location.pathname} label="Por Número" onClick={() => onClick?.(songbook, "")} />
        <SongbookLink path="por-nome" acronym={songbook?.acronym ?? ''} currentPath={location.pathname} label="Por Nome" onClick={() => onClick?.(songbook, "por-nome")} />
        <SongbookLink path="por-primeira-linha" acronym={songbook?.acronym ?? ''} currentPath={location.pathname} label="Por Primeira Linha" onClick={() => onClick?.(songbook, "por-primeira-linha")} />
    </Nav>
}

function SongbookLink({path, acronym, currentPath, label, onClick}: {path: string, acronym: string, currentPath: string, label: string, onClick?: () => void}) {
    const completePath = useMemo(() => `/${acronym.toLowerCase()}/${path}`, [path, acronym]);
    const isActive = useMemo(() => path === ''
        ? currentPath === completePath
        : currentPath.indexOf(completePath) == 0, [completePath, currentPath]);

    return <Nav.Item>
        <Nav.Link
            as={Link}
            active={isActive}
            to={completePath}
            onClick={() => onClick?.()}
        >
            {label}
        </Nav.Link>
    </Nav.Item>
}